import React, { Component } from 'react'
import * as THREE from 'three'
//import * as STATS from 'stats-js'

import "./threeContainer.scss"
import Wave from "./threeVisualWave"
import Circle from "./threeVisualCircle"
import Line from "./threeVisualLine"

let width = 0
let height  = 0
//let stats = null

export default class ThreeContainer extends Component {

  componentDidMount(){
    //Renderer Parameters
    let isSafari = navigator.userAgent.indexOf("Safari") === -1
    let renderer_antialias = false
    let renderer_alpha = true
    let renderer_pixelratio = 1
    let renderer_precision = 'highp'
    
    window.addEventListener('resize', this.onWindowResize, {passive: true})

    
    width = window.innerWidth
    switch (this.props.height) {
      case 'half':
        height = window.innerHeight / 2
        break
        default:
          height = window.innerHeight
    }

    //ADD SCENE
    this.scene = new THREE.Scene()

    //ADD CAMERA
    this.camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000)
    this.camera.lookAt(new THREE.Vector3( 0, 0, 0));
    this.camera.position.z = 10

    //ADD RENDERER
    //Turnoff Anialiasing on Safari for performance reason
    if(isSafari) {
      renderer_antialias = false
    }
    this.renderer = new THREE.WebGLRenderer({ antialias: renderer_antialias, alpha:renderer_alpha, devicePixelRatio:renderer_pixelratio, precision: renderer_precision })
    this.renderer.setClearColor('#000000', 0)
    this.renderer.setSize(width, height)
    this.mount.appendChild(this.renderer.domElement)


    switch (this.props.visualType) {
      case 'wave':
        this.animator = new Wave(this.scene, width, height, this.props.colorOne, this.props.colorTwo)
        break
      case 'circle':
        this.animator = new Circle(this.scene, width, height, this.props.colorOne, this.props.colorTwo)
        break
      case 'line':
        this.animator = new Line(this.scene, width, height, this.props.colorOne, this.props.colorTwo)
        break
      default:
      this.animator = new Wave(this.scene, width, height, this.props.colorOne, this.props.colorTwo)
    }
    
    this.start()
    this.animator.killAll()
    this.animator.init()
  }

  componentDidUpdate() {
    this.animator.updateColors(this.props.colorOne, this.props.colorTwo)
 
  }

  componentWillUnmount(){
    this.stop()
    this.animator.killAll()
    this.mount.removeChild(this.renderer.domElement)
  }

  start = () => {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate)
    }
  }

  stop = () => {
    cancelAnimationFrame(this.frameId)
  }
  
  animate = () => {
    //stats.begin()
    this.animator.render()
    this.renderScene()
    //stats.end()    
    this.frameId = window.requestAnimationFrame(this.animate)
  }
  
  renderScene = () => {
    this.renderer.render(this.scene, this.camera)
  }

  onWindowResize = () => {
    if(this.mount) {
      //Get Window Dimensions
      width = window.innerWidth
      switch (this.props.height) {
        case 'half':
          height = window.innerHeight / 2
          break
          default:
            height = window.innerHeight
      }
      this.camera.aspect = width / height
      this.camera.updateProjectionMatrix()
      this.renderer.setSize(width, height)
    }
  }

  render(){
    return(
      <div
        className={'three-container'}
        ref={(mount) => { this.mount = mount }}
      />
    )
  }
}
import React from 'react'
import Plx from 'react-plx';
import "./mouseButton.scss"
import AnchorLink from 'react-anchor-link-smooth-scroll'

const mouseParallaxData = [
  {
    start: 'self',
    duration: '10vh',
    properties: [
      {
        startValue: 1,
        endValue: 0,
        property: 'opacity'
      },
      {
        startValue: 0,
        endValue: 10,
        unit: 'vh',
        property: 'translateY',
      },      
    ]
  }
]

export default class MouseButton extends React.Component {
  render() {
    return (
      <Plx parallaxData={ mouseParallaxData } className="MouseButtonWrapper">                      
      <AnchorLink href="#SecondPage">
      <div className="MouseButton">
      </div>
      </AnchorLink>
      <div id="SecondPage"></div>
      </Plx>
  )
}}
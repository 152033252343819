import * as THREE from 'three'
import ColorUtils from '../utils/colorUtils'

let droplets = []

export default class Circle {
    constructor(scene, width, height, colorOne, colorTwo) {
        this.scene = scene
        this.width = width
        this.height = height
        this.colorOne = colorOne
        this.colorTwo = colorTwo
        this.colorUtils = new ColorUtils()
    } 

    init() {
			for (let i = 0; i <= Math.floor(Math.random()*3 + 1); i++) {
        this.createCircleGroup(Math.floor(Math.random()*20), Math.random()*2.5+0.25)
      }
    }

    killAll() {
      console.log("Kill All")
      while(this.scene.children.length > 0){ 
        this.scene.remove(this.scene.children[0]); 
      }
    }
    
    updateColors(colorOne, colorTwo) {
      this.colorOne = colorOne
      this.colorTwo = colorTwo
    }

    drawCircle(segmentCount, radius, material) {
      let circle_geometry = new THREE.Geometry();

      for (let i = 0; i <= segmentCount; i++) {
          let theta = (i / segmentCount) * Math.PI * 2
          circle_geometry.vertices.push(
              new THREE.Vector3(
                  Math.cos(theta) * radius,
                  Math.sin(theta) * radius,
                  0));
      }
      return new THREE.Line(circle_geometry, material)
    }
    
    createCircleGroup(num_of_circles, radius) {
      const sleepAmount = Math.random()*5000
      this.sleep(sleepAmount).then(() => {
        let	circles = new THREE.Object3D()
        let rotateX = Math.random()*45
        this.scene.add(circles)

        for (let i = 0; i <= num_of_circles; i++) {
          let rgb = this.colorUtils.getColor3RGB(this.colorOne, this.colorTwo, i, num_of_circles)
          let circle = this.drawCircle(128,((i/num_of_circles)*radius*1.25)+radius,new THREE.LineBasicMaterial({ color: rgb, opacity:0, transparent:true }))
          circle.rotation.x = rotateX  
          circles.add(circle)
        }
        circles.position.x = Math.random()*20-10
        circles.position.y = Math.random()*20-10
        droplets.push(circles)
      })
    }
    
    render() {
      for(let i=0,j=droplets.length;i<j;i++){
        if(droplets[i]) {
					//Loop through the circles
					for(let k=0,l=droplets[i].children.length;k<l;k++){
						//Get the next particle
						let circle = droplets[i].children[k]

            //let scale_ratio = 0.0025
            let scale_ratio = 0.005 + Math.random()/250
						circle.scale.x += scale_ratio
						circle.scale.y += scale_ratio
            circle.scale.z += scale_ratio
            circle.rotation.x -= scale_ratio/2.5

						if(circle.scale.x < 1.5){
							circle.material.opacity += Math.random()/125
						} else {
							circle.material.opacity -= Math.random()/500
							if(circle.material.opacity < 0.02){
								droplets[i].remove(circle)
                k--
                l--
							}
						}
					}
					if(droplets[i].children.length < 1) {
						droplets.splice(i, 1)
						this.createCircleGroup(50, Math.random()*3+ 0.25)
					}
				}
      }
    }
    
    
    sleep = (milliseconds) => {
      return new Promise(resolve => setTimeout(resolve, milliseconds))
    }
}
import * as THREE from 'three'
import ColorUtils from '../utils/colorUtils'

const LINENUM = 150
let	lines = new THREE.Object3D()

export default class Line {
    constructor(scene, width, height, colorOne, colorTwo) {
        this.scene = scene
        this.width = width
        this.height = height
        this.colorOne = colorOne
        this.colorTwo = colorTwo
        this.colorUtils = new ColorUtils()
    }

    init() {
      this.scene.add(lines)
      this.sleep(2000).then(() => {
        this.createLineGroup(LINENUM)
      })
    }

    updateColors(colorOne, colorTwo) {
      this.colorOne = colorOne
      this.colorTwo = colorTwo
      //console.log(this.colorOne)
      //console.log(this.colorTwo)
    }

    drawLine(length, material) {
      let line_geometry = new THREE.Geometry()
      let x = 0
      let y = Math.random()*this.height/100 + 1
      line_geometry.vertices.push(new THREE.Vector3( x, y, 0) )
      line_geometry.vertices.push(new THREE.Vector3( x, y+length, 0) )
      return new THREE.Line(line_geometry, material)
    }
    
    createLineGroup(num_of_lines) {
      for (let i = 0; i <= num_of_lines; i++) {
       const sleepAmount = Math.random()*2000
       this.sleep(sleepAmount).then(() => {
         if(num_of_lines > 0) {
          let rgb = this.colorUtils.getColor3RGB(this.colorOne, this.colorTwo, i, num_of_lines)
          let line = this.drawLine(0, new THREE.LineBasicMaterial( { color: rgb, linewidth:1, opacity:0, transparent:true } ))
          line.rotation.x = Math.random()*360
          line.rotation.y = Math.random()*360
          line.rotation.z = Math.random()*360
          lines.add(line)
         }
       })
      }
    }
    
    render() {
      lines.rotation.z +=0.001
      for(let i=0,j=lines.children.length;i<j;i++){
        let line = lines.children[i]
        if(line){
          line.geometry.vertices[1].y += 0.5
          line.scale.y += 0.01
          if(line.scale.y < 1.5 ){
            line.material.opacity += 0.01
          } else {
            line.material.opacity -= 0.02
          }
        
          if(line.material.opacity < .01) {
            lines.remove(line)
          }
        }
      }
      if(lines.children.length < LINENUM*3) {
        this.sleep(500).then(() => {
          this.createLineGroup(Math.floor(Math.random()*(LINENUM/20)))
        })
      }
      //console.log(lines.children.length)
    }
    
    sleep = (milliseconds) => {
      return new Promise(resolve => setTimeout(resolve, milliseconds))
    }
}
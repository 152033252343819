import * as THREE from 'three'
import ColorUtils from '../utils/colorUtils'

let wavelets = []
let sleepAmount = 0
const SLEEP_DELAY = 2500
const SLEEP_DELAY_CONSTANT = 500

export default class Wave {
    constructor(scene, width, height, colorOne, colorTwo) {
        this.scene = scene
        this.width = width
        this.height = height
        this.colorOne = colorOne
        this.colorTwo = colorTwo
        this.colorUtils = new ColorUtils()
    }

    init() {
      wavelets.length = 0
       for (let i = 0; i <= Math.floor(Math.random()*2)+1; i++) {
         if(i > 0) sleepAmount = Math.random()*SLEEP_DELAY + SLEEP_DELAY_CONSTANT
        this.createWaveGroup(Math.floor(Math.random()*50), Math.random()*4+2, sleepAmount)
      }
    }

    updateColors(colorOne, colorTwo) {
      this.colorOne = colorOne
      this.colorTwo = colorTwo
    }

    killAll() {
      console.log("Kill All")
      while(this.scene.children.length > 0){ 
        this.scene.remove(this.scene.children[0]); 
      }
    }
    
    drawWave(amplitude, material) {
        const wave_geometry = new THREE.Geometry()
        const screen_width = this.width* (Math.random()*3 + 1)
    
        for (let i = 0; i <= screen_width; i++) {
            let theta = (i-(screen_width/2))/10;
            wave_geometry.vertices.push(
                new THREE.Vector3(
                    theta,
                    Math.sin(theta) * amplitude,
                    0));
        }
        return new THREE.Line(wave_geometry, material);
      }
    
      createWaveGroup(num_of_waves, amplitude, sleepAmount) {
        this.sleep(sleepAmount).then(() => {
          let	waves = new THREE.Object3D();
          this.scene.add(waves);
          
          for (let i = 0; i <= num_of_waves; i++) {
            let rgb = this.colorUtils.getColor3RGB(this.colorOne, this.colorTwo, i, num_of_waves)
            let wave = this.drawWave((i/num_of_waves)*amplitude,new THREE.LineBasicMaterial({ color: rgb , opacity:0, transparent:true }));
            waves.add(wave);
          }
          waves.position.x = (Math.random()*-0.5)*(window.innerWidth / 5);
          wavelets.push(waves);
         })
      }
    
      render() {
        for(let i=0,j=wavelets.length;i<j;i++){
          //Make sure the object is defined
          if(wavelets[i]) {
          wavelets[i].rotation.y -=0.0002;
          //Loop through the wave
          let rotationFactor = Math.random()/1000
          for(let k=0,l=wavelets[i].children.length;k<l;k++){
            //Get the next wave
            const wave = wavelets[i].children[k];
    
            const scale_ratio = Math.random()/100;
            wave.scale.z += scale_ratio;
            wave.rotation.x += rotationFactor + 0.025;
            wave.position.x += 0.025;
    
            if(wave.scale.z < 1.5){
              if(wave.material.opacity < 0.5)
              wave.material.opacity += 0.005;
            } else {
              wave.material.opacity -= Math.random()/500;
              if(wave.material.opacity < 0.01){
                wavelets[i].remove(wave)
                k--
                l--
              }
            }
          }
          if(wavelets[i].children.length < 1) {
            wavelets.splice(i, 1)
            //sleepAmount = Math.random()*SLEEP_DELAY + SLEEP_DELAY_CONSTANT
            this.createWaveGroup(Math.floor(Math.random()*50), Math.random()*4+ 2, 0);
          }
         }
        }
        //console.log(wavelets.length)
      }
    
      sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
      }
}